import { createTheme } from "@mui/material";

import { colors } from "./styleVariables";

export const muiTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          "@media (max-width:480px)": {
            fontSize: 13,
          },
          backgroundColor: colors.blue,
          borderColor: colors.white,
          borderStyle: "solid",
          borderWidth: 1,
          fontSize: 14
        }
      }
    }
  },
  palette: {
    error: {
      contrastText: "#ffffff",
      main: colors.red,
    },
    primary: {
      contrastText: "#ffffff",
      main: colors.blue
    },
  },
  typography: {
    allVariants: {
      color: colors.text
    },
    fontFamily: "Poppins",
    fontSize: 14 /* 'fontSize: 14' equals 16px */,
    fontWeightBold: "600",
    fontWeightLight: "200",
    fontWeightMedium: "400",
    fontWeightRegular: "300"
  }
});
