import React, { FunctionComponent } from "react";

import * as styles from "./ErrorScreen.style";
import ResetSessionButton from "../resetSessionButton/ResetSessionButton";

interface IProps
{
  errorMessage: string;
}

const ErrorScreen: FunctionComponent<IProps> = ({ errorMessage }) => (
  <div css={styles.wrapper}>
    <ResetSessionButton/>
    <h1>{errorMessage}</h1>
  </div>
);

export default ErrorScreen;
