import create from "zustand";
import { immer } from "zustand/middleware/immer";

interface IAuthorizationStore
{
  isUserAuthorized: boolean | undefined;
  setIsUserAuthorized: (isUserAuthorized: boolean) => void;
}

const useAuthorizationStore = create(
  immer<IAuthorizationStore>(set => ({
    isUserAuthorized: undefined,
    setIsUserAuthorized: isUserAuthorized =>
    {
      set(state =>
      {
        state.isUserAuthorized = isUserAuthorized;
      });
    },
  }))
);

export default useAuthorizationStore;
