import { css } from "@emotion/react";

export const wrapper = css`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;
`;
