import { isAxiosError } from "axios";
import React, { FunctionComponent, useEffect, useMemo } from "react";

import * as styles from "./AuthorizationWrapper.style";
import { validateAuthCookie } from "../../../api/login";
import AppWrapper from "../../../app/appWrapper/AppWrapper";
import useAuthorizationStore from "../../../store/authorization.store";
import useNotificationsStore from "../../../store/notifications.store";
import SurveyPasswordScreen from "../surveyPasswordScreen/SurveyPasswordScreen";

interface IProps
{
  clickHash: string;
  finalLanguage: string;
  isSurveyPasswordProtected: boolean;
}

const AuthorizationWrapper: FunctionComponent<IProps> = ({ clickHash, finalLanguage, isSurveyPasswordProtected }) =>
{
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const addNotification = useNotificationsStore(state => state.addNotification);
  const setIsUserAuthorized = useAuthorizationStore(s => s.setIsUserAuthorized);
  let isUserAuthorized = useAuthorizationStore(s => s.isUserAuthorized);

  if(!isSurveyPasswordProtected)
  {
    isUserAuthorized = true;
  }

  useEffect(() =>
  {
    if(isUserAuthorized != null)
    {
      // validation already done
      return;
    }

    validateAuthCookie({
      clickHash,
      isPreview: urlParams.get("previewType") != null
    })
      .then(() =>
      {
        setIsUserAuthorized(true);
      })
      .catch(e =>
      {
        if(isAxiosError(e) && e.response?.status === 401)
        {
          setIsUserAuthorized(false);
          return;
        }

        addNotification({
          error: e,
          message: "notification_error_something_went_wrong",
          type: "error"
        });
      });
  }, [addNotification, clickHash, isUserAuthorized, setIsUserAuthorized, urlParams]);

  if(isUserAuthorized == null)
  {
    return null;
  }

  return (
    <div css={styles.wrapper}>
      {isUserAuthorized ? (
        <AppWrapper
          clickHash={clickHash}
          selectedLanguage={finalLanguage!}
        />
      ) : (
        <SurveyPasswordScreen clickHash={clickHash}/>
      )}
    </div>
  );
};

export default AuthorizationWrapper;
