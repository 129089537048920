import { SurveyPageView } from "@make-opinion-survey-generator/output-backend";

import { ICaptcha } from "../../store/captchas.store";

export const getAllCaptchaElements = (currentPage: SurveyPageView): ICaptcha[] =>
{
  const captchaElements: ICaptcha[] = [];

  currentPage.elements.forEach(questionOrStaticsArray =>
  {
    if(!Array.isArray(questionOrStaticsArray))
    {
      return;
    }

    const captchaElementsFromStaticsBlock = questionOrStaticsArray
      .filter(e => e.elementType === "captcha")
      .map(e => ({
        elementId: e.id as number,
        hasBeenSolved: false
      }));

    captchaElements.push(...captchaElementsFromStaticsBlock);      
  });

  return captchaElements;
};
