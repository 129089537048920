import React, { FunctionComponent } from "react";

import ErrorScreen from "../../components/common/errorScreen/ErrorScreen";
import LanguageSelectAndAppWrapper from "../languageSelectAndAppWrapper/LanguageSelectAndAppWrapper";

const ClickHashWrapper: FunctionComponent = () =>
{
  const urlParams = new URLSearchParams(window.location.search);
  const clickHash = urlParams.get("s");
  const isClickHashValid = clickHash !== null && clickHash.length > 0;

  if(!isClickHashValid)
  {
    return <ErrorScreen errorMessage="Click Hash missing"/>;
  }

  return (
    <LanguageSelectAndAppWrapper clickHash={clickHash}/>
  );
};

export default ClickHashWrapper;
