import { css } from "@emotion/react";

import { colors } from "../../styles/styleVariables";

export const wrapper = css`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vh 5vw 8vh;
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
`;

export const languageButtonsWrapper = css`
  max-height: 60vh;
  width: 100%;
  border-radius: 8px;
  padding: 30px 30px;
  max-width: 400px;
  display: flex;
  border: solid 1px #e8e8e8;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.13);
  flex-direction: column;
  overflow-y: auto;
  background-color: ${colors.white};
  button:last-of-type {
    margin-bottom: 0;
  }
`;
