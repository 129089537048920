import { LoginDto, ValidateCookieDto } from "@make-opinion-survey-generator/output-backend/src/shared/dto/login.dto";
import axios from "axios";

import { getEnvVar } from "../utils/environment";
import { applicationJsonHeader } from "../utils/helpers/api";

export const loginProtectedSurvey = async (loginData: LoginDto): Promise<void> =>
{
  await axios.post(
    `${getEnvVar("backendUrl")}login`,
    loginData,
    { headers: applicationJsonHeader }
  );
};

export const validateAuthCookie = async (validateCookie: ValidateCookieDto): Promise<void> =>
{
  await axios.post(
    `${getEnvVar("backendUrl")}login/validate`,
    validateCookie,
    { headers: applicationJsonHeader }
  );
};
