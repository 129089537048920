/* eslint-disable max-lines */

import {
  SinglePunchAnswerDto,
  TextboxAnswerDto,
  TAnswersDto,
  MultiPunchAnswerDto,
  MatrixAnswerDto,
  SliderAnswerDto,
  NpsAnswerDto,
  InputFieldAnswerDto,
  RatingAnswerDto,
  MarkableImageAnswerDto,
  TCommonAnswerValuesDto, MatrixAnswerV2Dto, AudioRecordingAnswerDto, AudioRecordingQualificationAnswerDto,
} from "@make-opinion-survey-generator/output-backend";
import create from "zustand";
import { immer } from "zustand/middleware/immer";

type TUpdateSinglePunchAnswer = Pick<SinglePunchAnswerDto, "selectedAnswer" | "element_type">;
type TUpdateMultiPunchAnswer = Pick<MultiPunchAnswerDto, "selectedAnswers" | "element_type">;
type TUpdateMatrixAnswer = Pick<MatrixAnswerDto, "selectedAnswersIds" | "element_type">;
type TUpdateMatrixAnswerV2 = Pick<MatrixAnswerV2Dto, "selectedAnswersIds" | "participantMatrixAnswers" | "element_type">;
type TUpdateInputFieldAnswer = Pick<InputFieldAnswerDto, "answerValue" | "element_type">;
type TUpdateNpsAnswer = Pick<NpsAnswerDto, "selectedValue" | "element_type">;
type TUpdateRatingAnswer = Pick<RatingAnswerDto, "selectedValue" | "element_type">;
type TUpdateSliderAnswer = Pick<SliderAnswerDto, "selectedValue" | "element_type">;
type TUpdateTextboxAnswer = Pick<TextboxAnswerDto, "answerValue" | "element_type">;
type TUpdateMarkableImageAnswer = Pick<MarkableImageAnswerDto, "markedPositions" | "element_type">;
export type TUpdateRecordingAnswer = Pick<AudioRecordingAnswerDto, "mimeType" | "bitrate" | "durationInMilliseconds" | "element_type" | "blobFile" | "resourceUrl" | "type">;
export type TUpdateAudioRecordingQualificationAnswer = Pick<AudioRecordingQualificationAnswerDto, "mimeType" | "bitrate" | "durationInMilliseconds" | "element_type" | "blobFile" | "resourceUrl" | "type">;

type TAnswerUpdate =
  TUpdateSinglePunchAnswer |
  TUpdateRatingAnswer |
  TUpdateMultiPunchAnswer |
  TUpdateMatrixAnswer |
  TUpdateMatrixAnswerV2 |
  TUpdateInputFieldAnswer |
  TUpdateNpsAnswer |
  TUpdateMarkableImageAnswer |
  TUpdateSliderAnswer |
  TUpdateTextboxAnswer |
  TUpdateRecordingAnswer |
  TUpdateAudioRecordingQualificationAnswer;

export interface IAnswersStore
{
  answersWithQuestionId: TAnswersDto[] | undefined;
  deleteAnswer: (questionId: number) => void;
  getAnswerByQuestionId: (questionId: number) => TAnswersDto | undefined;
  setAnswers: (answers: TAnswersDto[]) => void;
  updateAnswer: (questionId: number, update: TAnswerUpdate) => void;
}

export const useAnswersStore = create(
  immer<IAnswersStore>((set, get) => ({
    answersWithQuestionId: undefined,
    deleteAnswer: questionId =>
    {
      set(state =>
      {
        if(state.answersWithQuestionId == null)
        {
          console.error(`cannot delete answer with question ID '${questionId}', Answers have not been set yet`);
          return;
        }

        const answerToDeleteIndex = state.answersWithQuestionId.findIndex(a => a.element_id === questionId);
        const answerToDelete = state.answersWithQuestionId[answerToDeleteIndex];

        if(answerToDelete.element_type === "matrixQuestionV2" && answerToDelete.participantMatrixAnswers.length > 0)
        {
          answerToDelete.selectedAnswersIds = [];
          answerToDelete.participantMatrixAnswers.forEach(a => a.selectedAnswerIndices = []);
        }
        else 
        {
          state.answersWithQuestionId = state.answersWithQuestionId.filter(a => a.element_id !== questionId);
        }
      });
    },
    getAnswerByQuestionId: questionId =>
    {
      const { answersWithQuestionId } = get();

      if(answersWithQuestionId == null)
      {
        console.info("cannot get answer by question ID, Answers have not been set yet");
        return;
      }

      return answersWithQuestionId.find(a => a.element_id === questionId);
    },
    setAnswers: answers =>
    {
      set(state =>
      {
        state.answersWithQuestionId = answers;
      });
    },
    updateAnswer: (questionId, update) =>
    {
      set(state =>
      {
        if(state.answersWithQuestionId == null)
        {
          console.error(`cannot update answer with question ID '${questionId}', Answers have not been set yet`);
          return;
        }

        const questionIndex = state.answersWithQuestionId.findIndex(a => a.element_id === questionId);

        if(questionIndex === -1)
        {
          // answer does not exist yet and needs to be created

          const commonAnswerValues: TCommonAnswerValuesDto = {
            element_id: questionId,
          };

          let newAnswerWithQuestionId: TAnswersDto;

          switch (update.element_type)
          {
            case "singlePunchQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "singlePunchQuestion",
                selectedAnswer: update.selectedAnswer
              };
              break;
            case "multiPunchQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "multiPunchQuestion",
                selectedAnswers: update.selectedAnswers,
              };
              break;
            case "matrixQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "matrixQuestion",
                selectedAnswersIds: update.selectedAnswersIds,
              };
              break;
            case "matrixQuestionV2":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "matrixQuestionV2",
                participantMatrixAnswers: update.participantMatrixAnswers,
                selectedAnswersIds: update.selectedAnswersIds
              };
              break;
            case "sliderQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "sliderQuestion",
                selectedValue: update.selectedValue,
              };
              break;
            case "ratingQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "ratingQuestion",
                selectedValue: update.selectedValue,
              };
              break;
            case "netPromoterScoreQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "netPromoterScoreQuestion",
                selectedValue: update.selectedValue,
              };
              break;
            case "textboxQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                answerValue: update.answerValue,
                element_type: "textboxQuestion",
              };
              break;
            case "inputFieldQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                answerValue: update.answerValue,
                element_type: "inputFieldQuestion",
              };
              break;
            case "markableImageQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                element_type: "markableImageQuestion",
                markedPositions: update.markedPositions,
              };
              break;
            case "audioRecordingQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                bitrate: update.bitrate,
                blobFile: update.blobFile,
                durationInMilliseconds: update.durationInMilliseconds,
                element_type: "audioRecordingQuestion",
                mimeType: update.mimeType,
                resourceUrl: update.resourceUrl,
                type: update.type,
              };
              break;
            case "audioRecordingQualificationQuestion":
              newAnswerWithQuestionId = {
                ...commonAnswerValues,
                bitrate: update.bitrate,
                blobFile: update.blobFile,
                durationInMilliseconds: update.durationInMilliseconds,
                element_type: "audioRecordingQualificationQuestion",
                mimeType: update.mimeType,
                resourceUrl: update.resourceUrl,
                type: update.type
              };
              break;
            default:
              throw Error("update answer not implemented  this element_type");
          }

          state.answersWithQuestionId = state.answersWithQuestionId.concat(newAnswerWithQuestionId);
        }
        else
        {
          switch (update.element_type)
          {
            case "singlePunchQuestion":
              (state.answersWithQuestionId[questionIndex] as SinglePunchAnswerDto).selectedAnswer = update.selectedAnswer;
              break;
            case "multiPunchQuestion":
              (state.answersWithQuestionId[questionIndex] as MultiPunchAnswerDto).selectedAnswers = update.selectedAnswers;
              break;
            case "matrixQuestion":
              (state.answersWithQuestionId[questionIndex] as MatrixAnswerDto).selectedAnswersIds = update.selectedAnswersIds;
              break;
            case "matrixQuestionV2":
              (state.answersWithQuestionId[questionIndex] as MatrixAnswerV2Dto).selectedAnswersIds = update.selectedAnswersIds;
              (state.answersWithQuestionId[questionIndex] as MatrixAnswerV2Dto).participantMatrixAnswers = update.participantMatrixAnswers;
              break;
            case "sliderQuestion":
            case "ratingQuestion":
            case "netPromoterScoreQuestion":
              (state.answersWithQuestionId[questionIndex] as SliderAnswerDto | RatingAnswerDto | NpsAnswerDto).selectedValue = update.selectedValue;
              break;
            case "textboxQuestion":
            case "inputFieldQuestion":
              (state.answersWithQuestionId[questionIndex] as TextboxAnswerDto | InputFieldAnswerDto).answerValue = update.answerValue;
              break;
            case "markableImageQuestion":
              (state.answersWithQuestionId[questionIndex] as MarkableImageAnswerDto).markedPositions = update.markedPositions;
              break;
            case "audioRecordingQuestion":
              (state.answersWithQuestionId[questionIndex] as AudioRecordingAnswerDto) = {
                ...(state.answersWithQuestionId[questionIndex] as AudioRecordingAnswerDto),
                ...update
              };
              /* (state.answersWithQuestionId[questionIndex] as AudioRecordingAnswerDto).mimeType = update.mimeType;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingAnswerDto).bitrate = update.bitrate;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingAnswerDto).type = update.type;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingAnswerDto).blobFile = update.blobFile;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingAnswerDto).durationInMilliseconds = update.durationInMilliseconds;*/
              break;
            case "audioRecordingQualificationQuestion":
              (state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto) = {
                ...(state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto),
                ...update
              };
              /* (state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto).mimeType = update.mimeType;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto).bitrate = update.bitrate;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto).type = update.type;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto).blobFile = update.blobFile;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto).durationInMilliseconds = update.durationInMilliseconds;
              (state.answersWithQuestionId[questionIndex] as AudioRecordingQualificationAnswerDto).audioTestString = update.audioTestString;*/
              break;
            default:
              throw Error("update answer not implemented  this element_type");
          }
        }
      });
    }
  }))
);
