import { css } from "@emotion/react";

import { colors } from "../../../../styles/styleVariables";

export const wrapper = (widthInPx: number) => css`
  position: relative;
  margin-top: 10px;
  width: ${widthInPx}px;
  border-radius: 5px;
  padding: 16px 20px;
  cursor: pointer;
  color: #ffffff;
  overflow: hidden;
  font-size: 14px;
  transition: opacity ease .15s;
  :hover, :active {
    opacity: 0.75;
  }
  * {
    color: #ffffff;
  }
  > svg {
    fill: #ffffff;
    position: absolute;
    right: 6px;
    top: 6px;
    transform: scale(0.7);
  }
`;

export const contentWrapper = css`
  position: relative;
  z-index: 1;
  * {
    word-break: break-word;
  }
  h1 {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    font-size: 17px;
    font-weight: 600;
    svg {
      fill: #ffffff;
      width: 20px;
      height: auto;
      margin-right: 5px;
    }
  }
`;

export const errorWrapper = css`
  background-color: ${colors.red};  
`;

export const warningWrapper = css`
  background-color: rgb(255, 169, 66);  
`;

export const successWrapper = css`
  background-color: rgb(72, 163, 255);
`;

export const progressWrapper = (durationInMs: number) => css`
  width: 100%;
  transform: translateX(-100%);
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.11);
  animation: notificationProgress ${durationInMs}ms linear;

  @keyframes notificationProgress {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
