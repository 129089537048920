import create from "zustand";
import { immer } from "zustand/middleware/immer";

export interface ICaptcha
{
  elementId: number;
  hasBeenSolved: boolean;
}

interface ICaptchasStore
{
  captchas: ICaptcha[] | undefined;
  getCaptchaByElementId: (elemendId: number) => ICaptcha | undefined;
  setCaptchas: (captchas: ICaptcha[]) => void;
  updateCaptcha: (elementId: number, hasBeenSolved: boolean) => void;
}

export const useCaptchasStore = create(
  immer<ICaptchasStore>((set, get) => ({
    captchas: undefined,
    getCaptchaByElementId: elementId =>
    {
      const { captchas } = get();

      if(captchas == null)
      {
        console.info("cannot get captcha by element ID, captchas have not been set yet");
        return;
      }

      return captchas.find(c => c.elementId === elementId);
    },
    setCaptchas: captchas =>
    {
      set(state =>
      {
        state.captchas = captchas;
      });
    },
    updateCaptcha: (elementId, hasBeenSolved) =>
    {
      set(state =>
      {
        if(state.captchas == null)
        {
          throw new Error(`cannot update captcha with element ID '${elementId}', Captchas have not been set yet`);
        }

        const captchaIndex = state.captchas.findIndex(c => c.elementId === elementId);

        if(captchaIndex === -1)
        {
          throw new Error(`cannot update captcha with element ID '${elementId}', captcha not found`);
        }

        state.captchas[captchaIndex].hasBeenSolved = hasBeenSolved;
      });
    }
  }))
);
