import { IUserHasStartForSurvey, THasUserStartedSurvey } from "@make-opinion-survey-generator/output-backend";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import * as styles from "./LanguageSelectAndAppWrapper.style";
import { getUsersSelectedSurveyLanguage } from "../../api/start.api";
import AuthorizationWrapper from "../../components/common/authorizationWrapper/AuthorizationWrapper";
import ErrorScreen from "../../components/common/errorScreen/ErrorScreen";
import UsersSelectedSurveyLanguageContext from "../../context/usersSelectedSurveyLanguageContext";
import useNotificationsStore from "../../store/notifications.store";
import PageLoadingPlaceholder from "../appWrapper/pageLoadingPlaceholder/PageLoadingPlaceholder";
import LanguageSelect from "../languageSelect/LanguageSelect";

interface IProps
{
  clickHash: string;
}

const LanguageSelectAndAppWrapper: FunctionComponent<IProps> = ({ clickHash }) =>
{
  const addNotification = useNotificationsStore(state => state.addNotification);
  const [hasUserStartedSurvey, setHasUserStartedSurvey] = useState<THasUserStartedSurvey>();
  const [fetchHasUserStartedSurveyStatus, setFetchHasUserStartedSurveyStatus] = useState<"loading" | "success" | "error">("loading");
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const { i18n } = useTranslation("translation");
  const finalLanguage = (hasUserStartedSurvey as IUserHasStartForSurvey)?.selectedLanguageCode || selectedLanguage;

  useEffect(() =>
  {
    getUsersSelectedSurveyLanguage(clickHash)
      .then(data =>
      {
        setFetchHasUserStartedSurveyStatus("success");
        setHasUserStartedSurvey(data);
      })
      .catch(e =>
      {
        addNotification({
          error: e,
          message: "notification_error_something_went_wrong",
          type: "error"
        });

        setFetchHasUserStartedSurveyStatus("error");
      });
  }, [addNotification, clickHash]);

  useEffect(() =>
  {
    if(finalLanguage == null)
    {
      return;
    }

    i18n.changeLanguage(finalLanguage)
      .catch(e =>
      {
        addNotification({
          error: e,
          message: "notification_error_something_went_wrong",
          type: "error"
        });
      });

  }, [addNotification, finalLanguage, i18n]);

  if(fetchHasUserStartedSurveyStatus === "loading")
  {
    return <PageLoadingPlaceholder shouldFadeInDelayed/>;
  }

  if(fetchHasUserStartedSurveyStatus === "error")
  {
    return <ErrorScreen errorMessage="Something went wrong"/>;
  }

  if(hasUserStartedSurvey == null)
  {
    return <ErrorScreen errorMessage="Something went wrong"/>;
  }

  if(hasUserStartedSurvey.status === "no-start-found" && selectedLanguage == null)
  {
    return (
      <LanguageSelect
        setSelectedLanguage={setSelectedLanguage}
        availableLanguages={hasUserStartedSurvey.availableLanguagesCodes}
      />
    );
  }

  return (
    <div css={styles.wrapper}>
      <UsersSelectedSurveyLanguageContext.Provider value={finalLanguage!}>
        <AuthorizationWrapper
          clickHash={clickHash}
          finalLanguage={finalLanguage!}
          isSurveyPasswordProtected={hasUserStartedSurvey.surveyIsPasswordProtected}
        />
      </UsersSelectedSurveyLanguageContext.Provider>
    </div>
  );
};

export default LanguageSelectAndAppWrapper;
