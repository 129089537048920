import { AnimatePresence } from "framer-motion";
import React, { FunctionComponent, ComponentProps } from "react";

import Notification from "./notification/Notification";
import * as styles from "./NotificationWrapper.style";
import useNotificationsStore from "../../../store/notifications.store";

const NotificationWrapper: FunctionComponent<ComponentProps<"div">> = ({ ...props }) =>
{
  const notifications = useNotificationsStore(state => state.notifications);

  return (
    <div {...props} css={styles.wrapper}>
      <AnimatePresence>
        {notifications.map(notification => (
          <React.Suspense key={notification.id} fallback={null}>
            <Notification {...notification}/>
          </React.Suspense>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default NotificationWrapper;
