export const getCookieValue = (name: string): string => document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

export const deleteAllCookies = (): void =>
{
  const cookies = document.cookie.split("; ");

  for(const cookie of cookies)
  {
    const d = window.location.hostname.split(".");

    while(d.length > 0)
    {
      const cookieBase = encodeURIComponent(cookie.split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";

      const p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while(p.length > 0)
      {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};

// todo: give surveyId and delete cookie only for this survey
export const deleteSurveyCookie = (surveyId?: number): void =>
{
  const cookies = document.cookie.split("; ");
  // only delete cookie with name "survey_id_x"
  // e.g. survey_id_62
  // const surveyCookie = cookies.filter(cookie => cookie.split("=")[0] === `survey_id_${surveyId}`);
  const surveyCookies = cookies.filter(cookie => cookie.split("=")[0].startsWith("survey_id_"));
  for(const cookie of surveyCookies)
  {
    const d = window.location.hostname.split(".");

    while(d.length > 0)
    {
      const cookieBase = encodeURIComponent(cookie.split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
      const p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while(p.length > 0)
      {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};
