import { PostStartView, StartDto, THasUserStartedSurvey } from "@make-opinion-survey-generator/output-backend";
import axios from "axios";

import { getEnvVar } from "../utils/environment";
import { applicationJsonHeader } from "../utils/helpers/api";

export const postSurveyStart = async (startDto: StartDto, sessionHash: string | null): Promise<PostStartView> =>
{
  const postSurveyStartResponse = await axios.post<PostStartView>(
    `${getEnvVar("backendUrl")}start` + (sessionHash ? `?session_hash=${sessionHash}` : ""),
    startDto,
    {
      headers: applicationJsonHeader,
    }
  );

  return postSurveyStartResponse.data;
};

export const getUsersSelectedSurveyLanguage = async (clickHash: string): Promise<THasUserStartedSurvey> =>
{
  const getUsersSelectedSurveyLanguageResponse = await axios.get(`${getEnvVar("backendUrl")}start/language/${clickHash}`);
  return getUsersSelectedSurveyLanguageResponse.data;
};
