/* eslint-disable max-lines, @typescript-eslint/naming-convention */

import { MultiPunchAnswerDto as MultiPunchAnswerSurveyGenDto, SinglePunchAnswerDto as SinglePunchAnswerSurveyGenDto } from "@make-opinion-survey-generator/backend";
import {
  InputFieldAnswerDto, MarkableImageAnswerDto,
  MatrixAnswerDto, MultiPunchAnswerDto,
  NpsAnswerDto, RatingAnswerDto,
  SinglePunchAnswerDto, SinglePunchAnswerOther, SliderAnswerDto,
  TAnswersDto,
  TAnswersView,
  TCommonAnswerValuesDto, TextboxAnswerDto
} from "@make-opinion-survey-generator/output-backend";
import deepEqual from "deep-equal";

import { shuffleArray } from "./arrays";

export const shufflePunchAnswers = <T extends SinglePunchAnswerSurveyGenDto | MultiPunchAnswerSurveyGenDto>(answers: T[]): T[] =>
{
  const answersToShuffle = answers.filter(a => a.attributes.answerClass === "regular");
  const answersNoShuffle = answers.filter(a => a.attributes.answerClass !== "regular");
  const regularAnswersShuffled = shuffleArray(answersToShuffle);

  return [...regularAnswersShuffled, ...answersNoShuffle];
};

const transformAnswerViewToDto = (answerView: TAnswersView): TAnswersDto =>
{
  const { element_id } = answerView;

  const commonAnswerValues: TCommonAnswerValuesDto = {
    element_id,
  };

  let answerDto: TAnswersDto;

  switch (answerView.element_type)
  {
    case "singlePunchQuestion":
    {
      let selectedAnswerDto: SinglePunchAnswerDto["selectedAnswer"];

      if(answerView.singlePunchAnswerType === "regular")
      {
        selectedAnswerDto = {
          selectedAnswerId: answerView.selectedAnswerId,
          singlePunchAnswerType: answerView.singlePunchAnswerType,
        };
      }
      else
      {
        selectedAnswerDto = {
          otherAnswerValue: answerView.answerValue,
          selectedAnswerId: answerView.selectedAnswerId,
          singlePunchAnswerType: answerView.singlePunchAnswerType,
        };
      }

      answerDto = {
        ...commonAnswerValues,
        element_id,
        element_type: "singlePunchQuestion",
        selectedAnswer: selectedAnswerDto
      };
      break;
    }
    case "multiPunchQuestion":
      const selectedAnswersDto: MultiPunchAnswerDto["selectedAnswers"] = answerView.selectedAnswers.map(answer => 
      {
        if(answer.multiPunchAnswerType === "regular")
        {
          return {
            answerId: answer.answerId,
            multiPunchAnswerType: answer.multiPunchAnswerType,
          };
        }
        else
        {
          return {
            answerId: answer.answerId,
            multiPunchAnswerType: answer.multiPunchAnswerType,
            otherAnswerValue: answer.answerValue
          };
        }
      });

      answerDto = {
        ...commonAnswerValues,
        element_id,
        element_type: "multiPunchQuestion",
        selectedAnswers: selectedAnswersDto,
      };
      break;
    case "matrixQuestion":
      answerDto = {
        ...commonAnswerValues,
        element_type: "matrixQuestion",
        selectedAnswersIds: answerView.selectedAnswersIds,
      };
      break;
    case "matrixQuestionV2":
      throw new Error("Not implemented yet");
    case "sliderQuestion":
      answerDto = {
        ...commonAnswerValues,
        element_type: "sliderQuestion",
        selectedValue: answerView.selectedValue,
      };
      break;
    case "netPromoterScoreQuestion":
      answerDto = {
        ...commonAnswerValues,
        element_type: "netPromoterScoreQuestion",
        selectedValue: answerView.selectedValue,
      };
      break;
    case "textboxQuestion":
      answerDto = {
        ...commonAnswerValues,
        answerValue: answerView.answerValue,
        element_type: "textboxQuestion",
      };
      break;
    case "markableImageQuestion":
      answerDto = {
        ...commonAnswerValues,
        element_type: "markableImageQuestion",
        markedPositions: answerView.markedPositions,
      };
      break;
    case "inputFieldQuestion":
      answerDto = {
        ...commonAnswerValues,
        answerValue: answerView.answerValue,
        element_type: "inputFieldQuestion",
      };
      break;
    case "ratingQuestion":
      answerDto = {
        ...commonAnswerValues,
        element_type: "ratingQuestion",
        selectedValue: answerView.selectedValue,
      };
      break;
    case "audioRecordingQuestion":
      answerDto = {
        ...commonAnswerValues,
        bitrate: answerView.bitrate,
        durationInMilliseconds: answerView.durationInMilliseconds,
        element_type: "audioRecordingQuestion",
        mimeType: answerView.mimeType,
        resourceUrl: answerView.resourceUrl,
        type: answerView.type
      };
      break;
    case "audioRecordingQualificationQuestion":
      answerDto = {
        ...commonAnswerValues,
        bitrate: answerView.bitrate,
        durationInMilliseconds: answerView.durationInMilliseconds,
        element_type: "audioRecordingQualificationQuestion",
        mimeType: answerView.mimeType,
        resourceUrl: answerView.resourceUrl,
        type: answerView.type
      };
      break;
  }

  return answerDto;
};

export const transformAnswerViewsToDtos = (answerViews: TAnswersView[]): TAnswersDto[] =>
{
  if(!answerViews)
  {
    return [];
  }

  return answerViews.map(answerView => transformAnswerViewToDto(answerView));
};

export const getAreAnswerViewAndDtoEqual = (answerView: TAnswersView | undefined, answerDto: TAnswersDto | undefined): boolean =>
{
  if(answerView == null && answerDto == null)
  {
    return true;
  }

  if(answerView == null || answerDto == null)
  {
    return false;
  }

  if(answerView.element_type !== answerDto.element_type)
  {
    return false;
  }

  let areEqual = true;

  switch (answerView.element_type)
  {
    case "singlePunchQuestion":
    {
      if(answerView.selectedAnswerId !== (answerDto as SinglePunchAnswerDto).selectedAnswer.selectedAnswerId)
      {
        areEqual = false;
      }
      else if(
        answerView.singlePunchAnswerType === "other" &&
        answerView.answerValue !== ((answerDto as SinglePunchAnswerDto).selectedAnswer as SinglePunchAnswerOther).otherAnswerValue
      )
      {
        areEqual = false;
      }

      break;
    }
    case "multiPunchQuestion":
    {
      if(answerView.selectedAnswers.length !== (answerDto as MultiPunchAnswerDto).selectedAnswers.length)
      {
        areEqual = false;
      }
      else 
      {
        const sortedAnswerViewAnswers = [...answerView.selectedAnswers];
        sortedAnswerViewAnswers.sort((a, b) => a.answerId - b.answerId);
        const sortedAnswerDtoAnswers = [...(answerDto as MultiPunchAnswerDto).selectedAnswers];
        sortedAnswerDtoAnswers.sort((a, b) => a.answerId - b.answerId);

        for(let i = 0; i < sortedAnswerViewAnswers.length; i++)
        {
          const answerViewAnswer = sortedAnswerViewAnswers[i];
          const answerDtoAnswer = sortedAnswerDtoAnswers[i];

          if(answerViewAnswer.multiPunchAnswerType !== answerDtoAnswer.multiPunchAnswerType) 
          {
            areEqual = false;
          }
          else 
          {
            if(answerViewAnswer.answerId !== answerDtoAnswer.answerId) 
            {
              areEqual = false;
            }
            else 
            {
              if(answerViewAnswer.multiPunchAnswerType === "other" && answerDtoAnswer.multiPunchAnswerType === "other"
              && answerViewAnswer.answerValue !== answerDtoAnswer.otherAnswerValue) 
              {
                areEqual = false;
              }
            }
          }
        }
      }
      break;
    }
    case "matrixQuestion":
    case "matrixQuestionV2":
    {
      const selectedAnswerIdsViewSorted = answerView.selectedAnswersIds.slice().sort();
      const selectedAnswerIdsDtoSorted = (answerDto as MatrixAnswerDto).selectedAnswersIds.slice().sort();

      if(selectedAnswerIdsViewSorted.length !== selectedAnswerIdsDtoSorted.length
        || !selectedAnswerIdsViewSorted.every((answerId, index) => answerId === selectedAnswerIdsDtoSorted[index]))
      {
        areEqual = false;
      }
      break;
    }
    case "sliderQuestion":
    case "netPromoterScoreQuestion":
    case "ratingQuestion":
    {
      if(answerView.selectedValue !== (answerDto as SliderAnswerDto | NpsAnswerDto | RatingAnswerDto).selectedValue)
      {
        areEqual = false;
      }
      break;
    }
    case "textboxQuestion":
    case "inputFieldQuestion":
    {
      if(answerView.answerValue !== (answerDto as TextboxAnswerDto | InputFieldAnswerDto).answerValue)
      {
        areEqual = false;
      }
      break;
    }
    case "markableImageQuestion":
    {
      if(!deepEqual(answerView.markedPositions, (answerDto as MarkableImageAnswerDto).markedPositions))
      {
        areEqual = false;
      }
      break;
    }
    case "audioRecordingQuestion":
      areEqual = false;
      break;
    case "audioRecordingQualificationQuestion":
      areEqual = false;
      break;
  }

  return areEqual;
};
