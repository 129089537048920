import {
  IStandardSurveyResponse,
  TPreviewDto,
  TPreviewType,
  TSurveyResponse
} from "@make-opinion-survey-generator/output-backend";
import { AxiosError } from "axios";
import React, {
  FunctionComponent, Suspense, useEffect, useMemo, useState 
} from "react";
import { useQuery } from "react-query";

import * as styles from "./AppWrapper.style";
import PageLoadingPlaceholder from "./pageLoadingPlaceholder/PageLoadingPlaceholder";
import { fetchSurveyContent } from "../../api/pageContent.api";
import { postSurveyStart } from "../../api/start.api";
import ErrorScreen from "../../components/common/errorScreen/ErrorScreen";
import EnglishInTranslationLanguagesContext from "../../context/englishInTranslationLanguagesContext";
import PreviewValuesContext from "../../context/previewValuesContext";
import SurveyContext, { ISurveyContext } from "../../context/surveyContext";
import SurveyCreatorsLanguageContext from "../../context/surveyCreatorsLanguageContext";
import { useAnswersStore } from "../../store/answers.store";
import { useCaptchasStore } from "../../store/captchas.store";
import useNotificationsStore from "../../store/notifications.store";
import { transformAnswerViewsToDtos } from "../../utils/helpers/answers";
import { getAllCaptchaElements } from "../../utils/helpers/captchas";
import { getCookieValue } from "../../utils/helpers/cookies";
import { queryKeys } from "../../utils/helpers/queryKeys";

const AppContent = React.lazy(async () => import("../appContent/AppContent"));

interface IProps
{
  clickHash: string;
  selectedLanguage: string;
}

let lastPostSurveyStartViewCallTimestamp = 0;

const AppWrapper: FunctionComponent<IProps> = ({ clickHash, selectedLanguage }) =>
{
  const [hasSurveyStartBeenPosted, setHasSurveyStartBeenPosted] = useState<"loading" | "success" | "error">("loading");
  const [fetchedSurveyId, setFetchedSurveyId] = useState<number>();
  const setAnswers = useAnswersStore(state => state.setAnswers);
  const setCaptchas = useCaptchasStore(state => state.setCaptchas);
  const addNotification = useNotificationsStore(state => state.addNotification);

  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const surveyContextValue: ISurveyContext = useMemo(() => ({ surveyId: fetchedSurveyId || -1 }), [fetchedSurveyId]);

  const previewValues: TPreviewDto | undefined = useMemo(() =>
  {
    const previewType = urlParams.get("previewType") as TPreviewType;

    if(previewType == null)
    {
      return;
    }
    else if(previewType === "survey")
    {
      return {
        previewType: "survey"
      };
    }
    else if(previewType === "page")
    {
      return {
        pageId: Number(urlParams.get("pageId")),
        previewType: "page"
      };
    }
    else
    {
      return {
        elementId: Number(urlParams.get("elementId")),
        previewType: "element"
      };
    }
  }, [urlParams]);

  useEffect(() =>
  {
    const now = window.performance.now();

    // This is a workaround to prevent the postSurveyStartView call from being called multiple times (only relevant in development)
    // This could be replaced with react-query
    if((now - lastPostSurveyStartViewCallTimestamp) < 10)
    {
      return;
    }

    lastPostSurveyStartViewCallTimestamp = window.performance.now();

    const urlParamsAsObject = Object.fromEntries(urlParams.entries());
    const transformedUrlParams = Object.entries(urlParamsAsObject)
      .map(([key, value]) => ({ attributeKey: key, attributeValue: value }))
      .filter(({ attributeKey, attributeValue }) => attributeKey !== "s" && attributeValue != null);

    const sessionHash = urlParams.get("session_hash");

    postSurveyStart(
      {
        attributes: transformedUrlParams,
        clickHash,
        language: selectedLanguage,
        preview: previewValues,
        timeZoneOffset: new Date().getTimezoneOffset(),
        traffic_source: "Development" // TODO
      },
      sessionHash,
    )
      .then(({ surveyId }) =>
      {
        const sessionCookieValue = getCookieValue(`survey_id_${surveyId}`);
        const _urlParams = new URLSearchParams(window.location.search);
        const currentURL = location.protocol + "//" + location.host + location.pathname;

        _urlParams.set("session_hash", sessionCookieValue);
        window.history.replaceState(null, "", currentURL + "?" + _urlParams.toString());

        setFetchedSurveyId(surveyId);
        setHasSurveyStartBeenPosted("success");
      })
      .catch(e =>
      {
        addNotification({
          error: e,
          message: "notification_error_something_went_wrong",
          type: "error"
        });

        setHasSurveyStartBeenPosted("error");
      });
  }, [addNotification, clickHash, previewValues, selectedLanguage, urlParams]);

  const { data: surveyContent, error, status } = useQuery<TSurveyResponse, AxiosError>(
    queryKeys.surveyContent,
    async () => fetchSurveyContent(fetchedSurveyId),
    {
      enabled: hasSurveyStartBeenPosted === "success" && fetchedSurveyId != null,
      keepPreviousData: true,
      onError: e =>
      {
        addNotification({
          error: e,
          message: "notification_error_something_went_wrong",
          type: "error"
        });
      },
      onSuccess: data =>
      {
        if(data.surveyResponseType === "standard")
        {
          setAnswers(transformAnswerViewsToDtos(data.answersByUser));
          setCaptchas(getAllCaptchaElements(data.currentPage));
        }
      },
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: import.meta.env.PROD ? 3 : false
    }
  );

  const userLanguageSurveyAttribute = (surveyContent as IStandardSurveyResponse)?.surveyAttributes?.userLanguage;
  const isEnglishInTranslationLanguages = (surveyContent as IStandardSurveyResponse)?.surveyAttributes?.isEnglishInTranslationLanguages;

  if(status === "loading" || hasSurveyStartBeenPosted === "loading")
  {
    return <PageLoadingPlaceholder/>;
  }

  let errorMessage: string | undefined;

  if((error?.response?.data as any)?.error === "this survey is empty")
  {
    errorMessage = "Survey is empty and has no pages";
  }
  else if(error || hasSurveyStartBeenPosted === "error")
  {
    errorMessage = "Something went wrong";
  }
  else if(!fetchedSurveyId)
  {
    errorMessage = "Survey not found";
  }
  else if(!surveyContent)
  {
    errorMessage = "No Page content found";
  }

  if(errorMessage)
  {
    return <ErrorScreen errorMessage={errorMessage}/>;
  }

  return (
    <SurveyContext.Provider value={surveyContextValue}>
      <SurveyCreatorsLanguageContext.Provider value={userLanguageSurveyAttribute}>
        <EnglishInTranslationLanguagesContext.Provider value={isEnglishInTranslationLanguages}>
          <PreviewValuesContext.Provider value={previewValues}>
            <Suspense fallback={<PageLoadingPlaceholder/>}>
              <div css={styles.appContentWrapper}>
                <AppContent pageContent={surveyContent as TSurveyResponse}/>
              </div>
            </Suspense>
          </PreviewValuesContext.Provider>
        </EnglishInTranslationLanguagesContext.Provider>
      </SurveyCreatorsLanguageContext.Provider>
    </SurveyContext.Provider>
  );
};

export default AppWrapper;
