import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import React, { FormEvent, FunctionComponent, ReactElement, useState } from "react";

import * as styles from "./LoginScreen.style";
import { getEnvVar } from "../../../utils/environment";

interface IProps
{
  children: ReactElement;
}

// TODO: This should be in a shared repo
const LoginWrapper: FunctionComponent<IProps> = ({ children }) =>
{
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(localStorage.getItem("isLoggedIn") === "true");
  const [areWrongCredentials, setAreWrongCredentials] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  if(getEnvVar("isPasswordProtected") === "false")
  {
    // skip login if password protection is disabled
    return children;
  }

  if(isLoggedIn == null)
  {
    return null;
  }

  const login = (e: FormEvent): void =>
  {
    e.preventDefault();

    if(username === "admin" && password === "hUwkmPRZ32kACcp")
    {
      window.localStorage.setItem("isLoggedIn", "true");
      setIsLoggedIn(true);
    }
    else
    {
      setAreWrongCredentials(true);
    }
  };

  if(!isLoggedIn)
  {
    return (
      <div css={styles.wrapper}>
        <form css={styles.form} onSubmit={login}>
          <TextField
            label="Username"
            type="text"
            value={username}
            onChange={e =>
            {
              setAreWrongCredentials(false);
              setUsername(e.target.value);
            }}
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={e =>
            {
              setAreWrongCredentials(false);
              setPassword(e.target.value);
            }}
          />
          <Button
            variant="contained"
            size="large"
            disabled={!username || !password}
            type="submit">
            Login
          </Button>
          {areWrongCredentials && (
            <p css={styles.wrongCredentials}>
              Incorrect Username or Password
            </p>
          )}
        </form>
      </div>
    );
  }

  return children;
};

export default LoginWrapper;
