/* eslint-disable import/no-unused-modules */

import axios from "axios";
import i18n from "i18next";
import backend, { HttpBackendOptions } from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { getEnvVar } from "../utils/environment";

// TODO: This could be in a shared repo

const loadResources = async (url: string): Promise<any> =>
{
  const response = await axios.get(url);
  return response.data.translation;
};

const backendOptions: HttpBackendOptions = {
  allowMultiLoading: false,
  loadPath: locales => `${getEnvVar("backendUrl")}translations/${locales[0]}`,
  request: (options: any, url: any, payload: any, callback: any) =>
  {
    loadResources(url)
      .then(translations => callback(null, {
        data: translations,
        status: 200,
      }))
      .catch(e => callback(e, { status: 500 }));
  }
};

// This eslint rules just indicates there could be an error, however in this case, it is not
// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    backend: backendOptions,
    debug: false,
    fallbackLng: "en",
    lng: "en",
    react: {
      useSuspense: true
    },
  })
  .catch(e =>
  {
    throw Error("Translations could not be fetched: " + e);
  });
