import { TTranslations } from "@make-opinion-survey-generator/output-backend";
import create from "zustand";
import { immer } from "zustand/middleware/immer";

import { getRandomId } from "../utils/helpers/randomId";

type TNotificationType = "error" | "warning" | "success";

export interface INotification
{
  error?: Error | unknown;
  id: string;
  message: keyof TTranslations;
  type: TNotificationType;
}

interface IAnswersLogsStore
{
  addNotification: (notification: Omit<INotification, "id">) => void;
  deleteNotification: (id: string) => void;
  notifications: INotification[];
}

const useNotificationsStore = create(
  immer<IAnswersLogsStore>(set => ({
    addNotification: notification =>
    {
      if(notification.error)
      {
        console.error(notification.error);
      }

      const notificationWithId: INotification = {
        id: getRandomId(),
        ...notification,
      };
      
      set(state =>
      {
        state.notifications.unshift(notificationWithId);
      });
    },
    deleteNotification: id =>
    {
      set(state =>
      {
        state.notifications = state.notifications.filter(notification => notification.id !== id);
      });
    },
    notifications: []
  }))
);

export default useNotificationsStore;
