import Button from "@mui/material/Button";
import { AxiosError } from "axios";
import React, { FunctionComponent } from "react";
import { useQuery } from "react-query";

import * as styles from "./LanguageSelect.style";
import { fetchLanguages } from "../../api/languages.api";
import ErrorScreen from "../../components/common/errorScreen/ErrorScreen";
import useNotificationsStore from "../../store/notifications.store";
import { queryKeys } from "../../utils/helpers/queryKeys";
import PageLoadingPlaceholder from "../appWrapper/pageLoadingPlaceholder/PageLoadingPlaceholder";

interface IProps
{
  availableLanguages: string[];
  setSelectedLanguage: (language: string) => void;
}

const LanguageSelect: FunctionComponent<IProps> = ({ availableLanguages, setSelectedLanguage }) =>
{
  const addNotification = useNotificationsStore(state => state.addNotification);

  const { data: fetchedLanguages, error, status } = useQuery<Awaited<ReturnType<typeof fetchLanguages>>, AxiosError>(
    queryKeys.availableLanguages,
    async () => fetchLanguages(),
    {
      onError: e =>
      {
        addNotification({
          error: e,
          message: "notification_error_something_went_wrong",
          type: "error"
        });
      },
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: import.meta.env.PROD ? 3 : false,
      select: languages => languages.filter(language => availableLanguages.includes(language.LanguageCode))
    }
  );

  if(status === "loading")
  {
    return <PageLoadingPlaceholder shouldFadeInDelayed/>;
  }

  if(error || !fetchedLanguages)
  {
    return <ErrorScreen errorMessage="Something went wrong"/>;
  }

  return (
    <div css={styles.wrapper}>
      <h1>Please select your language</h1>
      <div css={styles.languageButtonsWrapper}>
        {availableLanguages.map(language =>
        {
          const languageName = fetchedLanguages.find(l => l.LanguageCode === language)?.languagename_en;

          if(!languageName)
          {
            return null;
          }

          return (
            <Button
              variant="outlined"
              type="button"
              size="large"
              onClick={() => setSelectedLanguage(language)}
              sx={{
                fontSize: 16,
                marginBottom: 2
              }}
              key={language}>
              {languageName}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default LanguageSelect;
