import { CircularProgress } from "@mui/material";
import React, { FunctionComponent, useEffect, useState } from "react";

import * as styles from "./PageLoadingPlaceholder.style";

interface IProps
{
  shouldFadeInDelayed?: boolean;
}

const PageLoadingPlaceholder: FunctionComponent<IProps> = ({ shouldFadeInDelayed }) =>
{
  const [shouldBeDisplayed, setShouldBeDisplayed] = useState<boolean>(!shouldFadeInDelayed);

  useEffect(() =>
  {
    const timer = setTimeout(() =>
    {
      setShouldBeDisplayed(true);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  if(!shouldBeDisplayed)
  {
    return null;
  }

  return (
    <div css={styles.wrapper}>
      <CircularProgress size={50}/>
    </div>
  );
};

export default PageLoadingPlaceholder;
