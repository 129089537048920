import { TSurveyResponse } from "@make-opinion-survey-generator/output-backend";
import axios from "axios";

import { getEnvVar } from "../utils/environment";

export const fetchSurveyContent = async (surveyId: number | undefined): Promise<TSurveyResponse> =>
{
  if(surveyId == null)
  {
    throw new Error("surveyId is null");
  }

  const pageContent = await axios.get(`${getEnvVar("backendUrl")}survey/${surveyId}`);
  return pageContent.data;
};
