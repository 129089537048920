import { css } from "@emotion/react";

export const appContentWrapper = css`
  animation-name: fadeInAppContentWrapper;
  animation-duration: .2s;
  animation-delay: .1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;

  @keyframes fadeInAppContentWrapper {
    from { opacity:0; }
    to { opacity:1; }
  }
`;
