import { css } from "@emotion/react";

export const wrapper = css`
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 30px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
`;

export const form = css`
  background-color: #ffffff;
  padding: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e5e5;
  width: 100%;
  max-width: 460px;

  > div {
    margin-bottom: 20px;
  }
`;

export const heading = css`
  font-size: 24px;
  margin-bottom: 4px;
`;

export const text = css`
  font-size: 16px;
  margin-bottom: 30px;
  
`;

export const wrongCredentials = css`
  font-weight: 400;
  color: red;
  margin: 26px 0 0;
`;
