export const environmentVariablesKeys = {
  backendUrl: "VITE_OUTPUT_FRONTEND_BACKEND_URL",
  isPasswordProtected: "VITE_OUTPUT_FRONTEND_IS_PASSWORD_PROTECTED",
  recaptchaV2SiteKey: "VITE_OUTPUT_FRONTEND_S3_RECAPTCHA_V2_SITE_KEY",
  s3BucketUrl: "VITE_OUTPUT_FRONTEND_S3_BUCKET_URL",
  surveyGenBackendUrl: "VITE_OUTPUT_FRONTEND_SURVEYGEN_BACKEND_URL",
};

// TODO: This could be in a shared repo
export const getEnvVar = (key: keyof typeof environmentVariablesKeys): string =>
{
  const envVar = import.meta.env[environmentVariablesKeys[key]];

  if(!envVar)
  {
    const errorMessage = `Environment variable ${environmentVariablesKeys[key]} is not defined.`;
    console.error(errorMessage);
    throw Error(errorMessage);
  }

  return envVar;
};

// TODO: This could be in a shared repo
export const checkIfEnvVariablesExist = (environmentVariablesKeys: Record<string, any>): void =>
{
  Object.values(environmentVariablesKeys).forEach(variable =>
  {
    if(!import.meta.env[variable])
    {
      const errorMessage = "Environment variable '" + variable + "' not set.";
      console.error(errorMessage);
      throw Error(errorMessage);
    }
  });
};

