import { css } from "@emotion/react";

import { colors } from "./styleVariables";

export const globalStyles = css`
  * {
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    word-break: break-word;
    hyphens: auto;
    :focus-visible {
      outline: solid 2px ${colors.orange};
    }
  }

  body {
    scroll-behavior: smooth;
  }
  
  p {
    font-weight: 300;
    font-size: 16px;
    color: ${colors.text};
  }
  
  h1, h2, h3, h4, h5, h6, p {
    line-height: 140%;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
  }
`;
