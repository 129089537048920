export const colors = {
  blue: "#439DF0",
  blueHovered: "#4193e2",
  green: "#50CD89",
  greenBrighter: "#14d16a",
  greenHovered: "#48b87d",
  lightGrey: "#E0E0E0",
  orange: "#ffba00",
  red: "#F1416C",
  redBrighter: "#ff1b52",
  redHovered: "#d03357",
  text: "#282828",
  textGrey: "#828282",
  white: "#ffffff",
};
