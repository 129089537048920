/* eslint-disable @typescript-eslint/naming-convention */

import axios from "axios";

// TODO: This could be in a shared repo

interface ILanguage
{
  LanguageCode: string;
  id: number;
  isActive: 1 | 0;
  languagename_en: string;
  languagename_local: string;
  sort_order: number;
  translate_none_of_this: string;
  translate_other: string;
}

import { getEnvVar } from "../utils/environment";

export const fetchLanguages = async (): Promise<ILanguage[]> =>
{
  const response = await axios.get(getEnvVar("surveyGenBackendUrl") + "api/languages");
  return response.data.languages;
};
