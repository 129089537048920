import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput
} from "@mui/material";
import Button from "@mui/material/Button";
import { isAxiosError } from "axios";
import React, { FormEvent, FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import * as styles from "./SurveyPasswordScreen.style";
import { loginProtectedSurvey } from "../../../api/login";
import useAuthorizationStore from "../../../store/authorization.store";
import useNotificationsStore from "../../../store/notifications.store";

interface IProps
{
  clickHash: string;
}

const SurveyPasswordScreen: FunctionComponent<IProps> = ({ clickHash }) =>
{
  const { t } = useTranslation("translation");
  const setIsUserAuthorized = useAuthorizationStore(state => state.setIsUserAuthorized);
  const addNotification = useNotificationsStore(state => state.addNotification);
  const [areWrongCredentials, setAreWrongCredentials] = useState<boolean>(false);
  const [shouldShowPassword, setShouldShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");

  const submitPassword = async (e: FormEvent): Promise<void> =>
  {
    e.preventDefault();

    try
    {
      await loginProtectedSurvey({ clickHash, password });
      setIsUserAuthorized(true);
    }
    catch (e)
    {
      if(isAxiosError(e) && e.response?.status === 401)
      {
        setAreWrongCredentials(true);
        return;
      }

      addNotification({
        error: e,
        message: "notification_error_something_went_wrong",
        type: "error"
      });
    }
  };

  return (
    <div css={styles.wrapper}>
      <form css={styles.form} onSubmit={submitPassword}>
        <h1 css={styles.heading}>{t("survey_password_screen_title")}</h1>
        <p css={styles.text}>{t("survey_password_screen_description")}</p>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">{t("survey_password_screen_input_label_text")}</InputLabel>
          <OutlinedInput
            label={t("survey_password_screen_input_label_text")}
            value={password}
            autoFocus
            type={shouldShowPassword ? "text" : "password"}
            onKeyDown={e =>
            {
              if(!password || e.key !== "Enter")
              {
                return;
              }

              void submitPassword(e);
            }}
            onChange={e =>
            {
              setAreWrongCredentials(false);
              setPassword(e.target.value);
            }}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label={t("survey_password_screen_toggle_password_visibility_button_aria_label_text")!}
                  onClick={() => setShouldShowPassword(!shouldShowPassword)}
                  edge="end">
                  {shouldShowPassword ? <VisibilityOff/> : <Visibility/>}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
        <Button
          variant="contained"
          size="large"
          disabled={!password || areWrongCredentials}
          type="submit">
          {t("survey_password_screen_submit_button_text")}
        </Button>
        {areWrongCredentials && (
          <p css={styles.wrongCredentials}>
            {t("survey_password_screen_wrong_credentials_text")}
          </p>
        )}
      </form>
    </div>
  );
};

export default SurveyPasswordScreen;
