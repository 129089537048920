import { SerializedStyles } from "@emotion/react";
import { CheckCircle, Close, Error, Warning } from "@mui/icons-material";
import { motion } from "framer-motion";
import React, {
  FunctionComponent, useEffect, useCallback, useMemo, ReactElement
} from "react";
import { useTranslation } from "react-i18next";

import * as styles from "./Notification.style";
import useNotificationsStore, { INotification } from "../../../../store/notifications.store";

const Notification: FunctionComponent<INotification> = ({ id, message, type }) =>
{
  const deleteNotification = useNotificationsStore(state => state.deleteNotification);

  const { t } = useTranslation("translation");
  const isVisibleDurationInMs = 3500;
  const widthInPx = 300;

  const closeNotification = useCallback(() => 
  {
    deleteNotification(id);
  }, [deleteNotification, id]);

  useEffect(() =>
  {
    if(type !== "success") 
    {
      return;
    }

    setTimeout(() =>
    {
      closeNotification();
    }, isVisibleDurationInMs);
  }, [closeNotification, type]);

  let headerText: string;
  let icon: ReactElement;
  let wrapperStyle: SerializedStyles;

  switch (type) 
  {
    case "error": {
      headerText = t("notification_error_header_text");
      icon = <Error/>;
      wrapperStyle = styles.errorWrapper;
      break;
    }
    case "warning": {
      headerText = t("notification_warning_header_text");
      icon = <Warning/>;
      wrapperStyle = styles.warningWrapper;
      break;
    }
    case "success": {
      headerText = t("notification_success_header_text");
      icon = <CheckCircle/>;
      wrapperStyle = styles.successWrapper;
      break;
    }
  }

  const animationVariants = useMemo(() => ({
    hidden: {
      opacity: .2,
      x: widthInPx + 50
    },
    removed: {
      opacity: 0,
      transition: {
        duration: .2,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        bounce: .35,
        duration: .7,
        type: "spring",
      },
      x: 0
    }
  }), []);

  return (
    <motion.div
      layout
      variants={animationVariants}
      initial="hidden"
      animate="visible"
      exit="removed"
      css={[styles.wrapper(widthInPx), wrapperStyle]}
      onClick={closeNotification}>
      <Close/>
      {type === "success" && <div css={styles.progressWrapper(isVisibleDurationInMs)}/>}
      <div css={styles.contentWrapper}>
        <h1>{icon}{headerText}</h1>
        <p>{t(message)}</p>
      </div>
    </motion.div>
  );
};

export default Notification;
