import { createContext } from "react";

export interface ISurveyContext
{
  surveyId: number;
}

const SurveyContext = createContext<ISurveyContext>({
  surveyId: 0,
});

export default SurveyContext;
