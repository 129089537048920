/* eslint-disable import/no-unused-modules */

import "reflect-metadata";

import { Global } from "@emotion/react";
import { TPreviewType } from "@make-opinion-survey-generator/output-backend";
import { ThemeProvider } from "@mui/material";
import axios from "axios";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";

import ClickHashWrapper from "./app/clickHashWrapper/ClickHashWrapper";
import LoginWrapper from "./components/common/loginScreen/LoginScreen";
import NotificationWrapper from "./components/common/notificationWrapper/NotificationWrapper";
import { globalStyles } from "./styles/globals.style";
import { muiTheme } from "./styles/mui.theme";
import { checkIfEnvVariablesExist, environmentVariablesKeys } from "./utils/environment";
import { deleteSurveyCookie } from "./utils/helpers/cookies";

import "../src/i18n/i18n";

checkIfEnvVariablesExist(environmentVariablesKeys);

const queryClient = new QueryClient();

axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);

const urlParams = new URLSearchParams(window.location.search);
const previewType = urlParams.get("previewType") as TPreviewType;

if(previewType != null)
{
  // delete survey cookies if preview is active so the preview is not influenced by the user's previous sessions
  deleteSurveyCookie();
}

root.render((
  <StrictMode>
    <LoginWrapper>
      <QueryClientProvider client={queryClient}>
        <Global styles={globalStyles}/>
        <ThemeProvider theme={muiTheme}>
          <ClickHashWrapper/>
          <NotificationWrapper/>
        </ThemeProvider>
      </QueryClientProvider>
    </LoginWrapper>
  </StrictMode>
));
