import { RestartAlt } from "@mui/icons-material";
import React, { FunctionComponent } from "react";

import * as styles from "./ResetSessionButton.style";
import { resetSession } from "../../../utils/helpers/session";

const ResetSessionButton: FunctionComponent = () =>
{
  if(import.meta.env.MODE !== "staging" && import.meta.env.MODE !== "development")
  {
    return null;
  }

  return (
    <button
      type="button"
      onClick={resetSession}
      css={styles.restartButton}>
      <RestartAlt/>
    </button>
  );
};

export default ResetSessionButton;
